@import './colors.scss';

.footer {
  position: fixed;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  background-color: $footer-background;
  color: $footer-color;
  width: 100%;
  padding: 10px;

  .madewithlove {
    i {
      margin: 5px;
      color: #FF1010;
    }
  }

  strong {
    color: $highlight;
  }
}

.legal {
  color: $main-color;
  text-decoration: none;
}

.rights {
  padding: 10px;
  color: $main-color;
  width: inherit;
}

.footerRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}


.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  strong {
    color: #504d4d;
  }

  img {
    width: 20px;
    margin-right: 5px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 800px) { 
  .rights {
    text-align: center;
  }

  .footer {
    background-color: #fff;
    color: #fff;
    font-size: 12px;
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  .legal {
    color: $footer-background;
  }

  .footerRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    &:first-of-type {
      background-color: $main-color;
    }

    &:last-of-type {
      font-size: 12px;
    }
  }
}

// iphone 7/8 plus
@media only screen and (max-width: 414px) {
  .footer {
    font-size: 12px;
  }
};
