@import './colors.scss';

.page {
  width: 85%;
  margin: 0 auto;
  margin-top: 70px;
}

.goToLogin {
  text-align: center;
  color: $main-color;
  margin-top: 20px;
  cursor: pointer;
}

.info {
  background-color: #F2F2F2;
  color: #434343;
  padding: 20px;
  margin-bottom: 35px;
  border-radius: 5px;
}
