$main-background: #F9F9F9;

$aside-background: #eee;
$aside-color: #504d4d;

$highlight: #245561;

$footer-background: #fff;
$footer-color: #504d4d;

$main-color: #504d4d;
$buttons: #245561;
$accent-color: #fff;

