@import './colors.scss';

.form {
  width: 70%;
  margin: 0 auto;
  margin-top: 150px;

  .register {
    background-color: #EDEDED !important;
    color: $main-color  !important;
    margin-top: 10px;
  }

  .resetPassword {
    text-align: center;
    margin-top: 20px;
    color: $main-color;
    cursor: pointer;

    strong {
      color: $highlight;
    }
  }

  .mobileLogo {
    display: none;
  }
}


@media only screen and (max-width: 800px) { 
  .form {
    :global {
      .title {
        display: none;
      }

      label {
        display: none;
      }

      input {
        margin-bottom: 10px;
      }
    }

    .mobileLogo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 50px;

      img {
        width: 150px;
        margin-bottom: 25px;
      }

      h1 {
        color: #006882;
        font-weight: bold;
        font-size: 2rem;
      }
    }
  }
}

// iphone 7/8 plus
@media only screen and (max-width: 414px) {
  .form {
    width: 95%;
    margin-top: 30px;
  }

};

