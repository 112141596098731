@import './colors.scss';

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  img {
    width: 150px;
  }
}

.aside {
  width: 400px;
  background-color: $aside-background;
  color: $aside-color;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;

  h1 {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: flex-start;
    flex-direction: column;
    height: 50vh;
    width: max-content;
    font-size: 2.8rem;

    span, strong {
      margin-bottom: 10px;
    }
  }

  .help, .legal {
    position: absolute;
    bottom: 80px;
    left: 0;
    text-align: center;
    right: 0;
    cursor: pointer;
  }

  .legal {
    bottom: 110px;
    text-decoration: none;
    color: $aside-color;
  }

  strong {
    color: $highlight;
  }
}


@media only screen and (max-width: 1100px) { 
  .aside {
    width: 300px;

    h1 {
      font-size: 2.5rem;
    }
  }
}


@media only screen and (max-width: 800px) {
  .aside {
    display: none;
    overflow: hidden;
  }
};

