@import './reset.scss';
@import './inputs.scss';
@import './colors.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
}

*{
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

p {
  line-height: 1.5;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

main {
  padding: 20px;
  width: calc(100vw - 400px);
}

#root {
  display: grid;
  grid-template-columns: 400px 1fr;
  background-color: $main-background;
  min-height: 100vh;
  padding-bottom: 40px;
}

#content {
  margin-left: 400px;
}

.btn {
  background-color: $buttons !important;
  color: $accent-color  !important;
  height: 44px;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
  width: initial;
}


.title {
  font-size: 3rem;
  color: $main-color;
  font-weight: bold;
  margin-bottom: 45px;
}

.errorinfo {
  background-color: red;
  color: #fff;
  background-color: #ff6161;
  color: #fff;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 20px;

  .errortitle {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  ul, li{
    list-style: square !important;
    padding: 5px;
  }


  li {
    margin-left: 15px;
  }
}


@media only screen and (max-width: 1100px) {
  main {
    padding: 20px;
    width: calc(100vw - 300px);
  }

  #root {
    display: grid;
    grid-template-columns: 300px 1fr;
    background-color: $main-background;
  }

  #content {
    margin-left: 300px;
  }
}


@media only screen and (max-width: 800px) {
  main {
    padding: 20px;
    width: calc(100vw);
    padding-bottom: 60px;
  }

  #root {
    display: grid;
    grid-template-columns: 1fr;
    background-color: $main-background;
  }

  #content {
    margin-left: 0;
  }

  .title {
    font-size: 2.5rem;
    text-align: center;
  }
};

