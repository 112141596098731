@import './colors.scss';

textarea {
  resize: vertical; /* user can resize vertically, but width is fixed */
  background-color: #fff;
  min-height: 100px;
  outline: none;
}

.inputError {
  color: #F44336;
  margin-bottom: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  
  i {
    font-size: 1.2rem;
    margin-right: 5px;
  }
}

input:not([type=checkbox]), select, textarea { 
  width: 100%;
  border: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  margin: 0 0 5px 0;
  height: 44px;
  padding: 0 8px;
  font-size: 16px;
  margin-bottom: 18px;
  border-radius: 2px;

  &::placeholder {
    color: #D9D9D9;
  }

  &:disabled {
    background-color: #eee;
    cursor: not-allowed;
  }

  &.invalid {
    border-bottom: 1px solid #F44336;
  }
}

textarea {
  padding-top: 10px;
}

.infoInput {
  position: relative;
  .tooltipIcon {
    height: 40px;
    bottom: 20px;
    font-size: 22px;
    color: #e4e4e4;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
  }
}

label {
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  color: $main-color;
  font-weight: 500;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;

  display: block;
  width: 100%;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url("../images/stripe.png");
  background-color: #fff;
  margin-bottom: 10px;

  &:focus {
    outline: none;
  }
}


.checkbox {
  display: flex;
  align-items: center;

  .checkmark {
    margin-right: 20px;
  }
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  &.invalid {
    border-bottom: 1px solid red;
  }
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $main-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

