@import './colors.scss';

.form {
  width: 70%;
  margin: 0 auto;
  margin-top: 150px;

  .title {
    margin-bottom: 30px;
  }

  .info {
    background-color: #F2F2F2;
    color: #434343;
    padding: 20px;
    margin-bottom: 35px;
    border-radius: 5px;
  }
}

.spinner {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.goToLogin {
  text-align: center;
  color: $main-color;
  margin-top: 20px;
  cursor: pointer;
}


@media only screen and (max-width: 414px) {
  .form {
    margin-top: 70px;
    width: 95%;
  }
}

