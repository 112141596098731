@import './colors.scss';

.header {
  padding: 10px 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100vw - 400px);

  img {
    width: 50px;
  }


  .logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    h2 {
      font-weight: bold;
      font-size: 1.6rem;
      margin-left: 15px;
    }
  }

  h2 {
    color: $main-color;
  }

  .lang {
    cursor: pointer;
    color: $main-color;
    i {
      margin-left: 10px;
    }
  }

  .languageTrigger {
    position: relative;
  }

  .languageDropdown {
    position: absolute;
    top: 20px;
    right: 0;
    height: 100px;
    min-width: 100px;
    z-index: 1000;
    background-color: #fff;
    height: max-content;

    li {
      font-weight: 500;
      cursor: pointer;
      border-bottom: 1px solid $main-background;
      padding: 15px;

      &:hover {
        background-color: $main-color;
        color: $accent-color;

      }
    }
  } 
}

.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
}


@media only screen and (max-width: 1100px) {
  .header {
    width: calc(100vw - 300px);
  }
}


@media only screen and (max-width: 414px) { 

  .header {
    width: 100vw;

    .languageTrigger {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}
