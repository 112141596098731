@import './colors.scss';

.page {
  width: 80%;
  margin: 0 auto;
  margin-top: 150px;

  :global {
    .title {
      text-align: center;
      margin-bottom: 100px;
    }
  }
}

.form {
  width: 85%;
  margin: 0 auto;
  margin-top: 70px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.title {
  @extend .grid;
  grid-template-columns: 100px 1fr;;
}

.goToLogin {
  text-align: center;
  color: $main-color;
  margin-top: 20px;
  cursor: pointer;
}

.info {
  background-color: #F2F2F2;
  color: #434343;
  padding: 20px;
  margin-bottom: 35px;
  border-radius: 5px;
}


@media only screen and (max-width: 1100px) {
  .title, .grid {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .form {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 414px) {
  .form {
    width: 95%;
  }
};
