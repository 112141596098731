@import './colors.scss';

.page {
  width: 90%;
  margin: 0 auto;
  margin-top: 70px;
}

.collapsibleBody {
  background-color: $accent-color;
  border-top: 1px solid $main-color;
  padding: 20px;
  margin-bottom: 1px;
};

.collapsibleHeader {
  padding: 10px;
  background-color: $accent-color;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  border-bottom: 1px solid $main-background;
  align-items: center;
}

.goToLogin {
  text-align: center;
  color: $main-color;
  margin-top: 20px;
  cursor: pointer;
}

.legalDocument {

  * {
    padding: 5px;
    padding-left: 0;
  }

  h1, h2, h3, h4 {
    font-weight: 500;
    color: $main-color;
  }


}
