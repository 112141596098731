@import './colors.scss';

.page {
  width: 80%;
  margin: 0 auto;
  margin-top: 150px;

  :global {
    .title {
      text-align: center;
      margin-bottom: 100px;
    }
  }
}


.goToLogin {
  text-align: center;
  color: $main-color;
  margin-top: 20px;
  cursor: pointer;
}
